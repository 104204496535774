import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Modulo } from 'src/app/modulos/modulo/modulo.component';
import { ModuloLogoModule } from './modulo-logo/modulo-logo.module';
import { ModuloContatoModule } from './modulo-contato/modulo-contato.module';
import { ModuloRedeSocialModule } from './modulo-rede-social/modulo-rede-social.module';
import { ModuloNewsletterModule } from './modulo-newsletter/modulo-newsletter.module';
import { BannerSliderSlickComponent } from 'src/app/modulos/banner-slider-slick/banner-slider-slick.component';import { FaleConoscoComponent } from 'src/app/modulos/fale-conosco/fale-conosco.component';
import { ProdutoDestaqueOneComponent } from 'src/app/modulos/produto-destaque-one/produto-destaque-one.component';
import { ProdutoDestaqueTwoComponent } from 'src/app/modulos/produto-destaque-two/produto-destaque-two.component';
import {LazyLoadImageModule} from 'ng-lazyload-image';
import { ProdutoPartialModule } from '../produto/produto-partial/produto-partial.module';
import { ModuloDestinosComponent } from 'src/app/modulos/modulo-destinos/modulo-destinos.component';
import { DestinoItemModule } from '../destinos/destino-item/destino-item.module';
import { PipeModule } from '../pipe/pipe.module';
import { EnderecoComponent } from 'src/app/modulos/endereco/endereco.component';
import { ModuloWhatsappModule } from './modulo-whatsapp/modulo-whatsapp.module';
import { ModuloFaleConoscoModule } from './modulo-fale-conosco/modulo-fale-conosco.module';
import { ProdutoExperienciaComponent } from '../../modulos/produto-experiencia/produto-experiencia.component';
import { ProdutoClassificacaoItemModule } from '../produto/produto-classificacao-item/produto-classificacao-item.module';
import { ProdutoDestaqueThreeComponent } from '../../modulos/produto-destaque-three/produto-destaque-three.component';
import { ProdutoEmbarcacaoItemModule } from '../produto/produto-embarcacao-item/produto-embarcacao-item.module';
import { BlogDestaqueComponent } from '../../modulos/blog/blog-destaque/blog-destaque.component';
import { BlogItemComponent } from '../../components/blog/blog-item/blog-item.component';
import { FrotaComponent } from '../../modulos/frota/frota.component';
import { RouterModule } from '@angular/router';
import { BannerSplideComponent } from '../../modulos/banner-splide/banner-splide.component';
import { LogoFooterModuloComponent } from '../../modulos/logo-footer-modulo/logo-footer-modulo.component';
import { MenuFooterComponent } from 'src/app/components/partial/menu-footer/menu-footer.component';
import { ContatoSuporteModuloComponent } from '../../modulos/contato-suporte-modulo/contato-suporte-modulo.component';
import { RedeSocialComponent } from 'src/app/modulos/rede-social/rede-social.component';
import { DestinosMenuModuloComponent } from '../../modulos/destinos-menu-modulo/destinos-menu-modulo.component';
import { ModulosDestaqueComponent } from 'src/app/components/modulo/modulos-destaque/modulos-destaque.component';
import { ProdutoCategoriaDestaqueComponent } from 'src/app/modulos/produto-categoria-destaque/produto-categoria-destaque.component';
import { ProdutoClassificacaoDestaqueComponent } from '../../modulos/produto-classificacao-destaque/produto-classificacao-destaque.component';
import { ProdutoBuscaComponent } from '../../modulos/produto-busca/produto-busca.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TripAdvisorModuloComponent } from 'src/app/modulos/trip-advisor-modulo/trip-advisor-modulo.component';
import { AvaliacaoComponent } from 'src/app/modulos/avaliacao/avaliacao.component';
import { ModuloParalaxComponent } from 'src/app/modulos/modulo-paralax/modulo-paralax.component';
import { ModuloBannerComponent } from 'src/app/modulos/modulo-banner/modulo-banner.component';

@NgModule({
  declarations: [
    Modulo,
    BannerSliderSlickComponent,
    ProdutoDestaqueOneComponent,
    ProdutoDestaqueTwoComponent,
    ModuloDestinosComponent,
    EnderecoComponent,
    ProdutoExperienciaComponent,
    ProdutoDestaqueThreeComponent,
    BlogDestaqueComponent,
    BlogItemComponent,
    FrotaComponent,
    BannerSplideComponent,
    LogoFooterModuloComponent,
    MenuFooterComponent,
    ContatoSuporteModuloComponent,
    RedeSocialComponent,
    DestinosMenuModuloComponent,
    ModulosDestaqueComponent,
    ProdutoCategoriaDestaqueComponent,
    ProdutoClassificacaoDestaqueComponent,
    ProdutoBuscaComponent,
    TripAdvisorModuloComponent,
    AvaliacaoComponent,
    ModuloParalaxComponent,
    ModuloBannerComponent
  ],
  exports:[
    Modulo,
    BannerSliderSlickComponent,
    EnderecoComponent,
    ProdutoExperienciaComponent,
    BlogDestaqueComponent,
    FrotaComponent,
    BannerSplideComponent,
    LogoFooterModuloComponent,
    MenuFooterComponent,
    ContatoSuporteModuloComponent,
    RedeSocialComponent,
    DestinosMenuModuloComponent,
    ModulosDestaqueComponent,
    ProdutoCategoriaDestaqueComponent,
    ProdutoClassificacaoDestaqueComponent,
    ProdutoBuscaComponent,
    TripAdvisorModuloComponent,
    AvaliacaoComponent,
    ModuloParalaxComponent,
    ModuloBannerComponent
  ],
  imports: [
    RouterModule,
    ModuloFaleConoscoModule,
    ModuloWhatsappModule,  
    DestinoItemModule,
    ProdutoPartialModule,
    LazyLoadImageModule,
    ModuloLogoModule, 
    ModuloContatoModule,
    ModuloRedeSocialModule,
    ModuloNewsletterModule,
    CommonModule,
    PipeModule,  
    ProdutoClassificacaoItemModule,
    ProdutoEmbarcacaoItemModule,
    ReactiveFormsModule,
    FormsModule
  ]
})
export class ModuloModule{}
