import { Component, Input, OnInit } from '@angular/core';
import { StorageService } from 'src/app/services/storage.service';
import { CartService } from 'src/app/services/cart.service';
import { DataService } from 'src/app/services/data.service';
import { Router } from '@angular/router';

@Component({
  selector: 'btn-cart',
  templateUrl: './btn-cart.component.html',
  styleUrls: ['./btn-cart.component.scss']
})
export class BtnCartComponent implements OnInit {

  public total = 0;
  public qtd   = 0;
  public configuracao = null;
  public color        = null;
  public lp:any    = null;
  @Input("isHome") isHome:any = false;

  constructor(
    private cart: CartService, 
    private data: DataService,
    private router: Router,
    private dataService: DataService
  ) { 

    this.lp = this.data.getLp();

  }

  /**
   * 
   * On click
   * 
   */
   _onClick(){

    if(this.lp == null){
      this.router.navigateByUrl("/carrinho");
    }else{
      this.router.navigateByUrl("/lp/"+this.lp.apelido+"/carrinho");
    }
    return false;

  }
  /**
   * 
   * To route
   * 
   * @param value 
   * 
   */
  toRoute(value){

    this.router.navigateByUrl(value);

  }
  /**
   * 
   * Ouvintes
   * 
   */
  onData(){ 

    this.cart.emitirUpdateTotalCart.subscribe(data => {
      this.setTotal(data);  
    });  

  }
  /**
   * 
   * Set total
   * 
   * @param data 
   * 
   */
  setTotal(data){

    this.total = data.total;
    this.qtd   = data.qtd; 
    this.data.setTotalCart(data);
  
  }
  /**
   * 
   * Set color
   * 
   */
 setColor(){

  if(this.configuracao != null){

    this.color = this.configuracao.cor_2;

  }

}
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit() {

    let dataTotal = this.data.getTotalCart();
    if(dataTotal != null){
      this.setTotal(dataTotal);
    }
    this.configuracao = this.dataService.getConfiguracao();
    this.setColor();

    this.onData();

  }

}
