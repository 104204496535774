import { AfterViewInit, Component, ElementRef, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { UsuarioService } from 'src/app/services/usuario.service';
import { NavigationEnd, NavigationStart, Router,Event as NavigationEvent } from '@angular/router';

declare var $:any;

@Component({
  selector: 'partial-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit,AfterViewInit {
 
  @ViewChild("header") header:ElementRef;
  public showMenu    = new EventEmitter();
  public classCustom = "";
  public dataItem:any = {termo: ""};
  public lp:any = null;
  public isHome = false;

  constructor(
    public usuarioService: UsuarioService,
    public router: Router,
    public data: DataService
  ) { }
  
  /**
   * 
   * Buscar
   * 
   */
  buscar(){

    if(this.lp == null){    
      this.router.navigate(["/servicos"],{queryParams:this.dataItem});
    }else{
      this.router.navigate(["/lp/"+this.lp.apelido+"/buscar"],{queryParams:this.dataItem});
    }

    return false;

  }
  /***
   * 
   * On data
   * 
   **/
   _showMenu(){

    this.showMenu.emit(null);  

  }
  /**
   * 
   * On resize
   * 
   */
  onResize(){

    if(window.innerWidth >= 767){
      this.classCustom = "";
    }else{
      this.classCustom = "mobile";
    }

  }
  /**
   * 
   * Seta a Class Fixed para o topo
   * 
   */
  setTopFixed(){

    if(this.header != null && this.header != undefined){

      let header = this.header.nativeElement;

      $(window).scroll(function(){
          
          var fromTop = $(this).scrollTop();
          var windowWidth = $(window).width();
          
          if(fromTop > 100 && windowWidth > 960){
            header.classList.add("animated","fadeIn","fixed");
          }else{
            header.classList.remove("fixed","animated","fadeIn");
          }

      });

    }
  
  }
  /**
   * 
   * On Data
   * 
   */
  onData(){

    this.router.events.forEach((event: NavigationEvent) => {
      
      if(event instanceof NavigationStart){
        
        if(event.url == "/" || event.url == ""){
          this.isHome = true;
        }else{
          this.isHome = false;
        }

      }
      if(event instanceof NavigationEnd) {

                
      }

    });

  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit():void{
    this.lp = this.data.getLp();  
  }
  /**
   * 
   * Init
   * 
   */
  ngAfterViewInit():void{
    this.onData();
  }

}
