import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'modulo-banner',
  templateUrl: './modulo-banner.component.html',
  styleUrls: ['./modulo-banner.component.scss']
})
export class ModuloBannerComponent implements OnInit {

  @Input("data") data:any = null; 
  public width = 0;

  constructor(){}

  /**
   * 
   * On resize
   * 
   ***/
  onResize(data:any){

    this.width = data.target.innerWidth;

  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit():void{
    this.width = window.innerWidth;
  }

}
