import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'modulo-paralax',
  templateUrl: './modulo-paralax.component.html',
  styleUrls: ['./modulo-paralax.component.scss']
})
export class ModuloParalaxComponent implements OnInit {

  @Input("data") data:any = {};
  @Input("classCustom") classCustom = "";
  @Input("template") template = "";
  @ViewChild("slider") slider;
  public image:any = null;
  public prefixo:any = "web";
  
  constructor(
    public router: Router
  ){}

  /**
   * 
   * To about
   * 
   */
  toAbout(){

    let numero = "5554991071264";
    window.open("https://"+this.prefixo+".whatsapp.com/send?phone="+numero,"__blank");
      
    return false;  

  }
  /**
   * 
   * Set img
   * 
   */
  setImg(){  

    this.image = "/images/modulo/paralax/"+this.data.imagem;

  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit(): void {
    this.setImg();
  }

}
